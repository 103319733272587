<template>
	<div
		:class="'panel programm_schedule ' +
			(isHeaderIndent? ' mt-0 pt-0 ' : '') +
			(isFooterIndent? ' mb-0 pb-0 ' : '') +
			block.appearance"
		:style="block.background_image_default.src ? 'background-image:url(' + block.background_image_default.src + ')' : ''"
	>
		<div
			class="container"
			:class="[
				isBrand? 'sk' : '',
			]"
		>
			<div v-if="isHeaderVisible" class="panel_header">
				<h2>{{ block.block_title }}</h2>
			</div>

			<ul class="program_accordeon_list">
				<li
					v-for="(item, index) in block.items"
					:key="index"
					class="row d-flex align-self-center justify-content-start"
				>
					<div class="col-12 col-lg-3">
						<div class="module_data">
							<div
								v-if="item.module_description !== null"
								class="description mt-2"
								v-html="item.module_description"
							/>
						</div>
					</div>

					<div class="col-12 col-lg-9">
						<div
							v-for="(sub_item, sub_index) in item.sub_items"
							:key="sub_index"
							class="module_item"
						>
							<!-- <div class="theme" /> -->
							<div class="title-block d-sm-flex justify-content-between">
								<div class="title mr-sm-2">
									{{ sub_item.module_item_title }}
								</div>
								<div class="button">
									<div v-if="sub_item.module_item_download_url">
										<a
											:href="sub_item.module_item_download_url"
											target="_blank"
											class="btn btn-outline-primary btn-icon icon-arrow_right"
										>{{ $t('more') }}</a>
									</div>
									<div v-else-if="sub_item.module_item_download_file">
										<a
											:href="sub_item.module_item_download_file"
											target="_blank"
											class="btn btn-outline-primary btn-icon icon-text"
										>{{ $t('more') }}</a>
									</div>
								</div>
							</div>

							<client-only>
								<b-collapse :id="'module-collapse-' + index + '_' + sub_index">
									<div class="description" v-html="sub_item.module_item_description" />
								</b-collapse>
							</client-only>

							<div class="module_footer">
								<b-button
									v-if="sub_item.module_item_description"
									v-b-toggle="'module-collapse-' + index + '_' + sub_index"
									v-b-tooltip.hover
									variant="resize"
									class="icon-chevron"
									:title="$t('more')"
								/>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<div v-if="isFooterVisible" class="panel_footer" v-html="block.footer" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'PanelGeneralAccordion',
	props: {
		block: { type: Object, required: true },
		index: { type: Number, required: true },
	},
	computed: {
		isHeaderVisible() {
			return this.block.display_header === 1;
		},
		isHeaderIndent() {
			return this.block.hide_top_indent === 1;
		},
		isFooterVisible() {
			return this.block.display_footer === 1;
		},
		isFooterIndent() {
			return this.block.hide_bottom_indent === 1;
		},
		isBrand() {
			return this.block.display_brand_background === 1;
		},
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/panel-schedule";
	.programm_schedule {
		// margin-top: 3rem;
		.panel_footer {
			margin-top: 1.5rem;
		}
		.title {
			margin-bottom: 0.5rem;
		}
		.schedule_list {
			border-bottom: 1px solid; // margin-bottom: 3rem;
		}
		.schedule_time {
			margin-bottom: 1rem;
			height: auto;
		}

		.title-block {
			// display: flex;
			.btn {
				white-space: nowrap;
			}
		}

		.module_footer {
			margin-top: 1.5rem;
			margin-bottom: 1.5rem;
		}
	}

</style>
