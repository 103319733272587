import { render, staticRenderFns } from "./PanelGeneralAccordion.vue?vue&type=template&id=4f35c4fe&scoped=true"
import script from "./PanelGeneralAccordion.vue?vue&type=script&lang=js"
export * from "./PanelGeneralAccordion.vue?vue&type=script&lang=js"
import style0 from "./PanelGeneralAccordion.vue?vue&type=style&index=0&id=4f35c4fe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f35c4fe",
  null
  
)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCollapse, BButton} from 'bootstrap-vue'
    installComponents(component, {BCollapse, BButton})
    


    import installDirectives from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBToggle, VBTooltip} from 'bootstrap-vue'
    installDirectives(component, {'b-toggle': VBToggle, 'b-tooltip': VBTooltip})
    

export default component.exports